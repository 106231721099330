import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const BrokerInviteLoadble = Loadable({
  loader: () => import('@/sections/BrokerInvite'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const BrokerInvite: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Broker Invite" />
      <BrokerInviteLoadble />
    </React.Fragment>
  );
};

export default BrokerInvite;
